import './App.css'
import Main from './components/Main'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Just review
        </p>
        <Main></Main>
      </header>
    </div>
  )
}

export default App

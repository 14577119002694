import React from 'react'
import '../components/Main.css'
import 'bootstrap/dist/css/bootstrap.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Main = () => {
  return (
    <Container fluid="sm">
      <Row>
        {/* <div className="col-sm-4"> */}
        <Col>
          <input name="search" id="search" type="search" placeholder='Search review' className='Search-input'></input>  <span> </span>
          <input type="button" name="searchButton" id="searchButton" value="search" className='Search-button'></input>
        </Col>
        {/* </div> */}
      </Row>
    </Container>
  )
}

export default Main
